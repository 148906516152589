import Layout from "components/layout";
import React from "react";
import Breadcrumb from "components/LegalPolicies/Breadcrumb";
import SEO from "components/seo";
import backToTopIcon from "assets/backToTop.svg";

function Disclaimer() {
  const data = [
    {
      title: "WEBSITE DISCLAIMER",
      description: `The information provided by Tomedes ("we" "us," or "our") on https://www.tomedes.com (the "Site") is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.`,
    },
    {
      title: "EXTERNAL LINKS DISCLAIMER",
      description:
        "The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.",
    },
  ];
  return (
    <>
      <SEO
        title="Legal Disclaimer - Tomedes"
        description="Review Tomedes' Legal Disclaimer for insights on our website's content accuracy, external links, and the boundaries of our responsibilities."
        keywords=""
        slug="/legal/disclaimer"
      />
      <Layout>
        <div div className="max-w-7xl p-4 mx-auto space-y-8 py-12">
          <Breadcrumb path={["Legal Policies", "Disclaimer"]} />
          <div>
            <h1 className="text-5xl md:leading-none leading-[50px] font-bold">
              DISCLAIMER
            </h1>
            <p className="font-opensans mt-4">
              Effective as of September 21, 2023
            </p>
          </div>
          <div className="flex flex-col mt-[55px] gap-8">
            {data.map((item, i) => (
              <div
                key={i}
                className="rounded-lg first:mt-8 last:mt-5 text-[#131313]"
              >
                <h1 className="text-[40px] leading-[55px] font-primary font-bold ">
                  {item.title}
                </h1>
                <p className="font-opensans leading-8 mt-5 ">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center mt-[50px]">
            <img
              src={backToTopIcon}
              alt="backIcon"
              className="cursor-pointer"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            />
            <span className="mt-4 font-primary">Back To Top</span>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Disclaimer;
